/* eslint-disable no-undef */
import * as Sentry from '@sentry/react';
import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import {
  BrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType
} from 'react-router-dom';

const V2App = React.lazy(() => import('./app/v2-app'));
const LoadingScreen = React.lazy(() =>
  import('@omega/core').then((m) => ({ default: m.LoadingScreen }))
);

// Import and initialise sentry
Sentry.init({
  environment: process.env.NODE_ENV || 'development',
  release: process.env.NX_COMMIT_HASH,
  dsn: 'https://173aca1be05ef67faa1837f0cc1e3982@o223588.ingest.us.sentry.io/4506892120817664',
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes
    }),
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration()
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    'localhost',
    /^https:\/\/(staging\.)?app\.trinity3\.gg\/api/
  ],
  replaysOnErrorSampleRate: 1.0
});

function Comp() {
  return (
    <Suspense fallback={<LoadingScreen />}>
      {/* As soon as we have loading screen available, use this as fallback for Suspense */}
      <Suspense fallback={<LoadingScreen />}>
        <BrowserRouter>
          <V2App />
        </BrowserRouter>
      </Suspense>
    </Suspense>
  );
}

const ProfiledComp = Sentry.withProfiler(Comp);
const root = createRoot(document.getElementById('root'));
root.render(<ProfiledComp />);
